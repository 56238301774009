<template>
  <InfoCard
    :icon="icon"
    :text="$tc(resource, 2)"
    :number="total"
    :progress-description="currentlyShowing"
  />
</template>

<script>
import InfoCard from "@/components/widgets/info-card";

export default {
  name: "ResourceStatistics",
  components: {
    InfoCard
  },
  props: {
    resource: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    showing: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    currentlyShowing() {
      return this.$tc("showing_n_records_in_this_page", this.showing, {
        items: this.showing
      });
    }
  }
};
</script>

<style scoped>
.info-box {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
  border: 1px solid #d3d3d3cc;
  min-height: 92px;
  margin-bottom: 0;
}
</style>
