<template>
  <section class="no-select">
    <div class="row">
      <div class="col-xs-12">
        <template v-if="!busy">
          <form enctype="multipart/form-data" novalidate>
            <div class="dropbox">
              <input 
                type="file" 
                name="file"
                ref="import_file"
                @change="filesChange($event.target.name, $event.target.files)"
                accept="text/csv" class="input-file">
                <p>
                  {{ $tc("drag_file") }}
                </p>
                <p>
                  <Icon name="fa fa-4x fa-upload" />
                </p>
            </div>
          </form>
        </template>
        <template v-if="busy">
          <div class="isbusy text-center text-gray">
            <Icon name="fa fa-4x fa-refresh fa-spin" />
          </div>
        </template>
      </div>
    </div>
    <template v-if="downloadBaseFileFunction">
      <hr />
      <p class="text-left">
        {{ $t("download_base_file_text") }}
        <a href="#" @click="downloadBaseFileFunction()">
          {{ $t("click_here") }}
        </a>
        .
      </p>
      <p class="text-left">
        {{ $t("csv_import_file_instructions") }}
      </p>
    </template>
  </section>
</template>

<script>
import Icon from '@/components/icons/icon.vue';

export default {
  name: 'ImportFileForm',
  components: {
    Icon,
  },
  props: {
    connectorId: {
      type: Number
    },
    deviceId: {
      type: Number
    },
    dataId: {
      type: Number
    },
    reset: {
      type: Boolean,
      default: () => true
    },
    downloadBaseFileFunction: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
  },
  watch: {
    reset: {
      handler(n, o) {
        if (n) this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.busy = false;
      if (this.$refs?.import_file) {
        this.$refs.import_file.value = "";
      }
    },
    filesChange(fieldName, fileList) {
      let self = this;

      let promise = new Promise((resolve) => {
        self.busy = true;
        resolve();
      })
      promise.then(() => {
        const formData = new FormData();

        formData.append("contract_id", self.contract.id);

        if (self?.connectorId) 
          formData.append("connector_id", self.connectorId);
        if (self?.deviceId)
          formData.append("device_id", self.deviceId);
          if (self?.dataId)
          formData.append("data_id", self.dataId);

        if (!fileList.length) return;

        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

        self.$emit("submit", formData);
      })
    }
  }
}
</script>

<style lang="scss">
  .dropbox {
    outline: 3px dashed #ddd;
    outline-offset: -10px;
    color: dimgray;
    padding: 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    outline: 3px dashed darkgray;
    background: #ddd;
    color: dimgray;
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 25px 0 0 0;
  }

  .isbusy {
    padding: 30px;
    min-height: 200px;
    position: relative;
  }
</style>